import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from "./organism/header/header.component";
import { FooterComponent } from "./organism/footer/footer.component";
import { TranslateModule } from "@ngx-translate/core";
import { LottieComponent } from 'ngx-lottie';
import { IntroComponent } from './intro/intro.component';
import { AboutComponent } from './about/about.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ServicesComponent } from './services/services.component';

const exportedComponents = [
  HeaderComponent,
  IntroComponent,
  AboutComponent,
  BlogComponent,
  ContactComponent,
  PortfolioComponent,
  ServicesComponent,
  FooterComponent
];

const exportedModules = [
  ReactiveFormsModule,
  FormsModule,
];

@NgModule({
  declarations: [
    ...exportedComponents
  ],
  imports: [
    ...exportedModules,
    CommonModule,
    TranslateModule,
    LottieComponent
  ],
  exports: [
    ...exportedComponents,
    ...exportedModules,
    TranslateModule
  ],
  providers: [],
})
export class SharedModule {}
