import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BaseComponent } from "../../utilities/base-component";
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import matchesSelector from 'desandro-matches-selector';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent extends BaseComponent implements AfterViewInit, OnInit {
 
  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    const isotopeGrid: any = document.querySelector('.portfolio__grid');

    if (isotopeGrid) {
      imagesLoaded(isotopeGrid, () => {
        const iso = new Isotope(isotopeGrid, {
          itemSelector: '.element-item',
          percentPosition: true,
          masonry: {
            columnWidth: '.element-item'
          }
        });

        const filterFns: any = {
          numberGreaterThan50: (itemElem: Element) => {
            const number = itemElem.querySelector('.number')?.textContent;
            return number ? parseInt(number, 10) > 50 : false;
          },
          ium: (itemElem: Element) => {
            const name = itemElem.querySelector('.name')?.textContent;
            return name ? /ium$/.test(name) : false;
          }
        };

        const filtersElem = document.querySelector('.filters-button-group');
        if (filtersElem) {
          filtersElem.addEventListener('click', (event) => {
            if (!matchesSelector(event.target as Element, 'button')) {
              return;
            }
            const filterValue = (event.target as Element).getAttribute('data-filter');
            const finalFilterValue = filterFns[filterValue!] || filterValue;
            iso.arrange({ filter: finalFilterValue });
          });
        }

        const buttonGroups = document.querySelectorAll('.button-group');
        buttonGroups.forEach((buttonGroup) => {
          buttonGroup.addEventListener('click', (event) => {
            if (!matchesSelector(event.target as Element, 'button')) {
              return;
            }
            buttonGroup.querySelector('.is-checked')?.classList.remove('is-checked');
            (event.target as Element).classList.add('is-checked');
          });
        });
      });
    }
  }
  
  ngOnInit(): void {
  
  }
  
}
  