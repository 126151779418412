import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BaseComponent } from "../../utilities/base-component";
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent extends BaseComponent implements AfterViewInit, OnInit {
 
  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    
    // Portfolio modal popup
    const modalPopupList = document.querySelectorAll(".popup-modal--open");
    const modalPopupClose = document.querySelectorAll(".modal__popup--close");

    if (modalPopupList && modalPopupClose) {
      modalPopupList.forEach((modal) => {
        modal.addEventListener("click", (event) => {
          event.preventDefault();
          const nextElement = modal.nextElementSibling as HTMLElement;
          if (nextElement && !nextElement.classList.contains("active")) {
            nextElement.classList.add("active");
            document.body.classList.add("overflow-y-hidden");
          }
          const popupOverlay = nextElement.querySelector(".modal_popup_overlay") as HTMLElement;
          if (popupOverlay) {
            popupOverlay.addEventListener("click", () => {
              nextElement.classList.remove("active");
            });
          }
        });
      });

      modalPopupClose.forEach((modalClose) => {
        modalClose.addEventListener("click", (event) => {
          event.preventDefault();
          const parentModal = modalClose.parentElement?.parentElement?.parentElement as HTMLElement;
          if (parentModal && parentModal.classList.contains("active")) {
            parentModal.classList.remove("active");
            document.body.classList.remove("overflow-y-hidden");
          }
        });
      });  
    }
  }
  
  ngOnInit(): void {
  }
}
