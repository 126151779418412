<!-- 
<app-header></app-header>
-->

<div class="main-container">
  <router-outlet></router-outlet>
</div>

<!-- 
<app-footer></app-footer>
-->