import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StateManager } from "./services/state-manager/state-manager.service";
import { BaseComponent } from "./utilities/base-component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent extends BaseComponent implements OnInit {

  constructor(private stateService: StateManager) {
    super();
  }
  
  ngOnInit(): void {
  }
}
