import { Component, OnInit } from '@angular/core';
import { BaseComponent } from "../../utilities/base-component";
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent extends BaseComponent implements OnInit {
 
  constructor() {
    super();
  }
  
  ngOnInit(): void {
  
  }
  
}
  